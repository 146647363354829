<!-- 账号列表 -->
<template>
  <div>
    <div class="mainBody">

      <!--搜索框-->
      <div class="filterBarAdmin">
        <!--id-->
        <div>
          <label for="accountId">id：</label>
          <input
            type="text"
            id="accountId"
            v-model.trim="accountId"
          >
        </div>
        <!--账号-->
        <div>
          <label for="account">{{ $t("MyAccount") }}：</label>
          <input
            type="text"
            id="account"
            v-model.trim="account"
          >
        </div>
        <!--联系人-->
        <div>
          <label for="contactName">{{ $t("ContactPerson") }}：</label>
          <input
            type="text"
            id="contactName"
            v-model.trim="contactName"
          >
        </div>
        <el-button @click="searchUserData">{{ $t("Search") }}</el-button>
        <!--Email-->
        <div>
          <label for="email">Email：</label>
          <input
            id="email"
            v-model.trim="email"
          >
        </div>
        <!--联系电话-->
        <div>
          <label for="contactNumber">{{ $t("ContactNum") }}：</label>
          <input
            type="number"
            id="contactNumber"
            v-model.number.trim="contactNum"
          >
        </div>
        <div></div>
        <el-button @click="addAdmin">{{ $t("AddPerson") }}</el-button>
      </div>

      <!--二级导航栏 全部/启用/禁用/异常-->
      <div class="navigatorAdmin">
        <span
          id="all"
          style="border-right:none"
          @click="active(1)"
          :class="{'active':activeUser===1}"
        >{{ $t("AllData") }}</span>
        <span
          id="start"
          style="border-right:none"
          @click="active(2)"
          :class="{'active':activeUser===2}"
        >{{ $t("Initiate") }}</span>
        <span
          id="banned"
          @click="active(3)"
          :class="{'active':activeUser===3}"
        >{{ $t("Banned") }}</span>
      </div>

      <!--表格-->
      <el-table
        :data="userList"
        border
        style="width: 99%;margin: 0 auto"
      >
        <!--id-->
        <el-table-column
          fixed
          width="180px"
          prop="id"
          label="id"
          align="center"
        >
        </el-table-column>
        <!--账号-->
        <el-table-column
          prop="account"
          :label="$t('MyAccount')"
          align="center"
        >
        </el-table-column>
        <!--联系人-->
        <el-table-column
          prop="name"
          :label="$t('RealName')"
          align="center"
        >
        </el-table-column>
        <!--Email-->
        <el-table-column
          prop="email"
          label="Email"
          align="center"
        >
        </el-table-column>
        <!--联系电话-->
        <el-table-column
          prop="mobile"
          :label="$t('ContactNum')"
          align="center"
        >
        </el-table-column>
        <!--创建时间-->
        <el-table-column
          prop="createTime"
          width="165px"
          :label="$t('CreateTime')"
          align="center"
        >
        </el-table-column>
        <!--状态-->
        <el-table-column
          width="100"
          :label="$t('Status')"
          align="center"
        >
          <template slot-scope="scope">{{ numToStatusAdmin(scope.row.status) }}</template>
        </el-table-column>
        <!--操作-->
        <el-table-column
          prop="operation"
          :label="$t('Operation')"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="initiateAdmin(scope.row)"
              :disabled="myRight === 1 || scope.row.status === 0"
            >{{ $t("Initiate") }}
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="banAdmin(scope.row)"
              :disabled="myRight === 1 || scope.row.status === 1"
            >{{ $t("Banned") }}
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="editAuthorityDialog(scope.row,'editAdminDialog','editAdminForm')"
              :disabled="myRight === 1"
            >{{ $t("Edit") }}
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="deleteUserData(scope.row)"
              :disabled="myRight !== 3"
              style="color: red"
            >{{ $t("DeleteData") }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!--分页-->
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="userTotal"
        style="margin: 10px auto 10px 20px"
      >
      </el-pagination>
    </div>

    <!--添加用户弹窗-->
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('AddPerson')"
      :visible.sync="addAdminDialog"
      append-to-body
      center
      :show-close="false"
    >
      <el-form
        :model="addAdminForm"
        ref="addAdmin"
        :rules="rules"
        class="addAdmin"
        v-if="addAdminDialog"
        label-width="fit-content"
      >
        <!--账号-->
        <el-form-item
          :label="$t('MyAccount')+':'"
          prop="account"
        >
          <el-input
            v-model="addAdminForm.account"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!--密码-->
        <el-form-item
          :label="$t('Password')+':'"
          prop="passWord"
        >
          <el-input
            v-model="addAdminForm.passWord"
            show-password
            maxlength="16"
            @input="addAdminForm.passWord = addAdminForm.passWord.replace(/[^\a-\z\A-\Z0-9\!\@\#\$\%\^\&\*\_]/g, '')"
          ></el-input>
        </el-form-item>
        <!--姓名-->
        <el-form-item
          :label="$t('ContactPerson')+':'"
          prop="name"
        >
          <el-input
            v-model="addAdminForm.name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!--Email-->
        <el-form-item
          label="Email"
          prop="email"
        >
          <el-input
            v-model="addAdminForm.email"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!--电话-->
        <el-form-item :label="$t('ContactNum')+':'">
          <el-input
            v-model.number.trim="addAdminForm.mobile"
            autocomplete="off"
            type="number"
          ></el-input>
        </el-form-item>
        <div></div>
        <!--系统管理-->
        <el-form-item :label="$t('SystemManagement')+':'">
          <!--<el-dropdown trigger="click" @command="handleCommand('s')">-->
          <el-dropdown
            trigger="click"
            @command="handleCommand"
          >
            <span class="el-dropdown-link">
              {{ sIsV }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="['v','s','addAdminForm']">{{ $t("Visiable") }}</el-dropdown-item>
              <el-dropdown-item :command="['h','s','addAdminForm']">{{ $t("Hide") }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <!--消息管理-->
        <el-form-item :label="$t('MessageManagement')+':'">
          <!--<el-dropdown trigger="click" @command="handleCommand('s')">-->
          <el-dropdown
            trigger="click"
            @command="handleCommand"
          >
            <span class="el-dropdown-link">
              {{ mIsV }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="['v','m','addAdminForm']">{{ $t("Visiable") }}</el-dropdown-item>
              <el-dropdown-item :command="['h','m','addAdminForm']">{{ $t("Hide") }}</el-dropdown-item>
              <el-dropdown-item :command="['all','m','addAdminForm']">{{ $t("AllRight") }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <!--用户管理-->
        <el-form-item :label="$t('UserManagement')+':'">
          <!--<el-dropdown trigger="click" @command="handleCommand('s')">-->
          <el-dropdown
            trigger="click"
            @command="handleCommand"
          >
            <span class="el-dropdown-link">
              {{ uIsV }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="['v','u','addAdminForm']">{{ $t("Visiable") }}</el-dropdown-item>
              <el-dropdown-item :command="['h','u','addAdminForm']">{{ $t("Hide") }}</el-dropdown-item>
              <el-dropdown-item :command="['eo','u','addAdminForm']">{{ $t("EditOnly") }}</el-dropdown-item>
              <el-dropdown-item :command="['all','u','addAdminForm']">{{ $t("AllRight") }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <!--管理员管理-->
        <el-form-item :label="$t('AdminManagement')+':'">
          <!--<el-dropdown trigger="click" @command="handleCommand('s')">-->
          <el-dropdown
            trigger="click"
            @command="handleCommand"
          >
            <span class="el-dropdown-link">
              {{ aIsV }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="['v','a','addAdminForm']">{{ $t("Visiable") }}</el-dropdown-item>
              <el-dropdown-item :command="['h','a','addAdminForm']">{{ $t("Hide") }}</el-dropdown-item>
              <el-dropdown-item :command="['eo','a','addAdminForm']">{{ $t("EditOnly") }}</el-dropdown-item>
              <el-dropdown-item :command="['all','a','addAdminForm']">{{ $t("AllRight") }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <!--内容管理-->
        <el-form-item :label="$t('ContentManagement')+':'">
          <!--<el-dropdown trigger="click" @command="handleCommand('s')">-->
          <el-dropdown
            trigger="click"
            @command="handleCommand"
          >
            <span class="el-dropdown-link">
              {{ cIsV }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="['v','c','addAdminForm']">{{ $t("Visiable") }}</el-dropdown-item>
              <el-dropdown-item :command="['h','c','addAdminForm']">{{ $t("Hide") }}</el-dropdown-item>
              <el-dropdown-item :command="['all','c','addAdminForm']">{{ $t("AllRight") }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <!--帖子管理-->
        <el-form-item :label="$t('PostManagement')+':'">
          <!--<el-dropdown trigger="click" @command="handleCommand('s')">-->
          <el-dropdown
            trigger="click"
            @command="handleCommand"
          >
            <span class="el-dropdown-link">
              {{ pIsV }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="['v','p','addAdminForm']">{{ $t("Visiable") }}</el-dropdown-item>
              <el-dropdown-item :command="['h','p','addAdminForm']">{{ $t("Hide") }}</el-dropdown-item>
              <el-dropdown-item :command="['all','p','addAdminForm']">{{ $t("AllRight") }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <!--财务管理-->
        <el-form-item :label="$t('FinanceManagement')+':'">
          <!--<el-dropdown trigger="click" @command="handleCommand('s')">-->
          <el-dropdown
            trigger="click"
            @command="handleCommand"
          >
            <span class="el-dropdown-link">
              {{ fIsV }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="['v','f','addAdminForm']">{{ $t("Visiable") }}</el-dropdown-item>
              <el-dropdown-item :command="['h','f','addAdminForm']">{{ $t("Hide") }}</el-dropdown-item>
              <el-dropdown-item :command="['all','f','addAdminForm']">{{ $t("AllRight") }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <!--行业报告-->
<!--        <el-form-item :label="$t('IndustryReport')+':'">
          &lt;!&ndash;<el-dropdown trigger="click" @command="handleCommand('s')">&ndash;&gt;
          <el-dropdown
            trigger="click"
            @command="handleCommand"
          >
            <span class="el-dropdown-link">
              {{ iIsV }}<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="['v','i','addAdminForm']">{{ $t("Visiable") }}</el-dropdown-item>
              <el-dropdown-item :command="['h','i','addAdminForm']">{{ $t("Hide") }}</el-dropdown-item>
              <el-dropdown-item :command="['all','i','addAdminForm']">{{ $t("AllRight") }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>-->
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
        v-if="addAdminDialog"
      >
        <el-button @click="addAdminDialog = false; $refs['addAdmin'].resetFields()">{{ $t("Cancle") }}</el-button>
        <el-button
          type="primary"
          :loading="loading"
          @click="addAdminData('addAdmin')"
        >{{ $t("Confirm") }}</el-button>
      </div>
    </el-dialog>

    <!--编辑管理员弹窗-->
    <el-dialog
      :close-on-click-modal="false"
      :title="$t('EditPerson')"
      :visible.sync="editAdminDialog"
      append-to-body
      center
      :show-close="false"
    >

      <el-form
        :model="editAdminForm"
        ref="editAdmin"
        :rules="rules"
        class="addAdmin"
        label-width="fit-content"
      >
        <!--账号-->
        <el-form-item :label="$t('MyAccount')+':'">
          <el-input
            v-model="editAdminForm.account"
            disabled
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!--姓名-->
        <el-form-item :label="$t('ContactPerson')+':'">
          <el-input
            v-model="editAdminForm.name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!--电话-->
        <el-form-item
          :label="$t('ContactNum')+':'"
          prop="mobile"
        >
          <el-input
            v-model="editAdminForm.mobile"
            autocomplete="off"
            type="number"
          ></el-input>
        </el-form-item>
        <!--Email-->
        <el-form-item label="Email">
          <el-input
            v-model="editAdminForm.email"
            autocomplete="off"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <!--系统管理-->
        <el-form-item :label="$t('SystemManagement')+':'">
          <!--<el-dropdown trigger="click" @command="handleCommand('s')">-->
          <el-dropdown
            trigger="click"
            @command="handleCommand"
          >
            <span class="el-dropdown-link">
              {{ sIsV }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="['v','s','editAdminForm']">{{ $t("Visiable") }}</el-dropdown-item>
              <el-dropdown-item :command="['h','s','editAdminForm']">{{ $t("Hide") }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <!--消息管理-->
        <el-form-item :label="$t('MessageManagement')+':'">
          <!--<el-dropdown trigger="click" @command="handleCommand('s')">-->
          <el-dropdown
            trigger="click"
            @command="handleCommand"
          >
            <span class="el-dropdown-link">
              {{ mIsV }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="['v','m','editAdminForm']">{{ $t("Visiable") }}</el-dropdown-item>
              <el-dropdown-item :command="['h','m','editAdminForm']">{{ $t("Hide") }}</el-dropdown-item>
              <el-dropdown-item :command="['all','m','editAdminForm']">{{ $t("AllRight") }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <!--用户管理-->
        <el-form-item :label="$t('UserManagement')+':'">
          <!--<el-dropdown trigger="click" @command="handleCommand('s')">-->
          <el-dropdown
            trigger="click"
            @command="handleCommand"
          >
            <span class="el-dropdown-link">
              {{ uIsV }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="['v','u','editAdminForm']">{{ $t("Visiable") }}</el-dropdown-item>
              <el-dropdown-item :command="['h','u','editAdminForm']">{{ $t("Hide") }}</el-dropdown-item>
              <el-dropdown-item :command="['eo','u','editAdminForm']">{{ $t("EditOnly") }}</el-dropdown-item>
              <el-dropdown-item :command="['all','u','editAdminForm']">{{ $t("AllRight") }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <!--管理员管理-->
        <el-form-item :label="$t('AdminManagement')+':'">
          <!--<el-dropdown trigger="click" @command="handleCommand('s')">-->
          <el-dropdown
            trigger="click"
            @command="handleCommand"
          >
            <span class="el-dropdown-link">
              {{ aIsV }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="['v','a','editAdminForm']">{{ $t("Visiable") }}</el-dropdown-item>
              <el-dropdown-item :command="['h','a','editAdminForm']">{{ $t("Hide") }}</el-dropdown-item>
              <el-dropdown-item :command="['eo','a','editAdminForm']">{{ $t("EditOnly") }}</el-dropdown-item>
              <el-dropdown-item :command="['all','a','editAdminForm']">{{ $t("AllRight") }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <!--内容管理-->
        <el-form-item :label="$t('ContentManagement')+':'">
          <!--<el-dropdown trigger="click" @command="handleCommand('s')">-->
          <el-dropdown
            trigger="click"
            @command="handleCommand"
          >
            <span class="el-dropdown-link">
              {{ cIsV }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="['v','c','editAdminForm']">{{ $t("Visiable") }}</el-dropdown-item>
              <el-dropdown-item :command="['h','c','editAdminForm']">{{ $t("Hide") }}</el-dropdown-item>
              <el-dropdown-item :command="['all','c','editAdminForm']">{{ $t("AllRight") }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <!--帖子管理-->
        <el-form-item :label="$t('PostManagement')+':'">
          <!--<el-dropdown trigger="click" @command="handleCommand('s')">-->
          <el-dropdown
            trigger="click"
            @command="handleCommand"
          >
            <span class="el-dropdown-link">
              {{ pIsV }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="['v','p','editAdminForm']">{{ $t("Visiable") }}</el-dropdown-item>
              <el-dropdown-item :command="['h','p','editAdminForm']">{{ $t("Hide") }}</el-dropdown-item>
              <el-dropdown-item :command="['eo','p','editAdminForm']">{{ $t("EditOnly") }}</el-dropdown-item>
              <el-dropdown-item :command="['all','p','editAdminForm']">{{ $t("AllRight") }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <!--财务管理-->
        <el-form-item :label="$t('FinanceManagement')+':'">
          <!--<el-dropdown trigger="click" @command="handleCommand('s')">-->
          <el-dropdown
            trigger="click"
            @command="handleCommand"
          >
            <span class="el-dropdown-link">
              {{ fIsV }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="['v','f','editAdminForm']">{{ $t("Visiable") }}</el-dropdown-item>
              <el-dropdown-item :command="['h','f','editAdminForm']">{{ $t("Hide") }}</el-dropdown-item>
              <el-dropdown-item :command="['eo','f','editAdminForm']">{{ $t("EditOnly") }}</el-dropdown-item>
              <el-dropdown-item :command="['all','f','editAdminForm']">{{ $t("AllRight") }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>
        <!--行业报告    暂时隐藏-->
        <!--        <el-form-item :label="$t('IndustryReport')+':'">
          &lt;!&ndash;<el-dropdown trigger="click" @command="handleCommand('s')">&ndash;&gt;
          <el-dropdown
            trigger="click"
            @command="handleCommand"
          >
            <span class="el-dropdown-link">
              {{ iIsV }}<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="['v','i','editAdminForm']">{{ $t("Visiable") }}</el-dropdown-item>
              <el-dropdown-item :command="['h','i','editAdminForm']">{{ $t("Hide") }}</el-dropdown-item>
              <el-dropdown-item :command="['all','i','editAdminForm']">{{ $t("AllRight") }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-form-item>-->
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="editAdminDialog = false;$refs['editAdmin'].resetFields()">{{ $t("Cancle") }}</el-button>
        <el-button
          type="primary"
          :loading="loading"
          @click="editUserData('editAdmin')"
        >{{ $t("Confirm") }}</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import api from "@/fetch/api";
//    import axios from 'axios'

import mixin from "@/assets/js/mixin";

export default {
  mixins: [mixin],
  data() {
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
    //            邮箱验证
    const validateEmail = (rule, value, callback) => {
      if (value !== "") {
        if (value.indexOf("@") === -1) {
          callback(new Error(this.$t("ValidEmail")));
        }
      } else {
        callback(new Error(this.$t("EmptyEmail")));
      }
      callback();
    };
    return {
      myRight: data.administratorPermission,
      uploadDialog: false,
      addAdminDialog: false,
      editAdminDialog: false,
      authorityDialog: false,
      userList: [],
      activeUser: 1,
      chamberId: data.chamberId,
      adminId: data.id,
      currentAdmin: data.id,
      userTotal: 0,
      pageSize: 10,
      currentPage: 1,

      // 搜索用
      accountId: "",
      account: "",
      contactName: "",
      email: "",
      contactNum: "",
      address: "",
      //                sIsV:'',
      sIsV: this.$t("Visiable"),
      mIsV: this.$t("Visiable"),
      uIsV: this.$t("Visiable"),
      aIsV: this.$t("Visiable"),
      cIsV: this.$t("Visiable"),
      pIsV: this.$t("Visiable"),
      fIsV: this.$t("Visiable"),
      iIsV: this.$t("Visiable"),
      formLabelWidth: "100px",

      initUser: {
        chamberId: data.chamberId,
        adminId: data.id,
        page: 1,
        currentAdmin: data.id,
      },

      loading: false,

      addAdminForm: {
        account: "",
        passWord: "",
        name: "",
        email: "",
        mobile: "",
        adminId: data.id,
        systemPermission: 1,
        messagePermission: 1,
        userPermission: 1,
        administratorPermission: 1,
        contentPermission: 1,
        postPermission: 1,
        financePermission: 1,
        industryReportPermission: 1,
        chamberId: data.chamberId,
        currentAdmin: data.id,
      },

      editAdminForm: {
        account: "",
        adminId: data.id,
        id: 0,
        email: "",
        mobile: "",
        name: "",
        systemPermission: "",
        userPermission: "",
        administratorPermission: "",
        contentPermission: "",
        messagePermission: "",
        postPermission: "",
        financePermission: "",
        industryReportPermission: "",
        currentAdmin: data.id,
      },
      rules: {
        account: [
          { required: true, message: this.$t("EmptyAccount"), trigger: "blur" },
        ],
        passWord: [
          {
            required: true,
            message: this.$t("EmptyPassword"),
            trigger: "blur",
          },
          {
            min: 8,
            max: 16,
            message: this.$t("PasswordLengthTips"),
            trigger: "blur",
          },
        ],
        name: [
          { required: true, message: this.$t("EmptyName"), trigger: "blur" },
        ],
        email: [{ required: true, validator: validateEmail, trigger: "blur" }],
      },

      authorityParam: {
        account: 2,
        name: 2,
        mobile: 2,
        id: 1,
        adminId: data.id,
        systemPermission: 0,
        messagePermission: 0,
        userPermission: 0,
        administratorPermission: 0,
        contentPermission: 0,
        postPermission: 0,
        financePermission: 0,
        industryReportPermission: 0,
      },
    };
  },
  watch: {
    uploadDataForm: {
      deep: true,
      handler(val) {
        console.log("Watch fileList", val.fileList);
      },
    },
  },
  mounted() {
    this.initUserData(1);
  },
  methods: {
    //            添加用户： addAdmin ==> addAdminDialog ==> handleCommand同步addAdminForm ==> addAdminData传给后台
    //            编辑用户： editAuthorityDialog获取点击单位信息 ==> editAdminDialog ==> handleCommand同步editAdminForm ==> editUserData传给后台

    //            0 启用    1 禁用 绑定 筛选栏值 根据状态判断
    active(num) {
      this.activeUser = num;
      if (num === 1) {
        delete this.initUser.status;
      } else {
        this.initUser.status = num - 2;
      }
      this.initUserData(1);
    },

    //            初始化用户列表
    initUserData(currentPage) {
      this.initUser.page = currentPage;
      api.getAdmin(this.initUser).then((res) => {
        console.log(res);
        this.userList = res.data.pageList;
        this.userTotal = res.data.total;
      });
    },

    //            搜索用户列表
    searchUserData() {
      this.accountId
        ? (this.initUser.id = this.accountId)
        : delete this.initUser.id;
      this.account
        ? (this.initUser.account = this.account)
        : delete this.initUser.account;
      this.contactName
        ? (this.initUser.name = this.contactName)
        : delete this.initUser.name;
      this.email
        ? (this.initUser.email = this.email)
        : delete this.initUser.email;
      this.contactNum
        ? (this.initUser.mobile = this.contactNum)
        : delete this.initUser.mobile;
      //                console.log('searchAccount',this.initUser)
      this.initUserData(1);
    },

    //            分页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.initUserData(currentPage);
    },

    //            管理员权限设置
    //            0不可见 1可见 2可编辑但不可删 3所有权限
    handleCommand(command) {
      console.log("command:", command);
      switch (command[1]) {
        //                    系统管理
        case "s":
          if (command[0] === "v") {
            this.sIsV = this.$t("Visiable");
            this[command[2]].systemPermission = 1;
          } else {
            this.sIsV = this.$t("Hide");
            this[command[2]].systemPermission = 0;
          }
          break;
        //                    消息管理
        case "m":
          if (command[0] === "v") {
            this.mIsV = this.$t("Visiable");
            this[command[2]].messagePermission = 1;
          } else if (command[0] === "h") {
            this.mIsV = this.$t("Hide");
            this[command[2]].messagePermission = 0;
          } else {
            this.mIsV = this.$t("AllRight");
            this[command[2]].messagePermission = 3;
          }
          break;
        //                    用户管理
        case "u":
          if (command[0] === "v") {
            this.uIsV = this.$t("Visiable");
            this[command[2]].userPermission = 1;
          } else if (command[0] === "h") {
            this.uIsV = this.$t("Hide");
            this[command[2]].userPermission = 0;
          } else if (command[0] === "eo") {
            this.uIsV = this.$t("EditOnly");
            this[command[2]].userPermission = 2;
          } else {
            this.uIsV = this.$t("AllRight");
            this[command[2]].userPermission = 3;
          }
          break;
        //                    管理员管理
        case "a":
          if (command[0] === "v") {
            this.aIsV = this.$t("Visiable");
            this[command[2]].administratorPermission = 1;
          } else if (command[0] === "h") {
            this.aIsV = this.$t("Hide");
            this[command[2]].administratorPermission = 0;
          } else if (command[0] === "eo") {
            this.aIsV = this.$t("EditOnly");
            this[command[2]].administratorPermission = 2;
          } else {
            this.aIsV = this.$t("AllRight");
            this[command[2]].administratorPermission = 3;
          }
          break;
        //                    内容管理
        case "c":
          if (command[0] === "v") {
            this.cIsV = this.$t("Visiable");
            this[command[2]].contentPermission = 1;
          } else if (command[0] === "h") {
            this.cIsV = this.$t("Hide");
            this[command[2]].contentPermission = 0;
          } else {
            this.cIsV = this.$t("AllRight");
            this[command[2]].contentPermission = 3;
          }
          break;
        //                    帖子管理
        case "p":
          if (command[0] === "v") {
            this.pIsV = this.$t("Visiable");
            this[command[2]].postPermission = 1;
          } else if (command[0] === "h") {
            this.pIsV = this.$t("Hide");
            this[command[2]].postPermission = 0;
          } else if (command[0] === "eo") {
            this.pIsV = this.$t("EditOnly");
            this[command[2]].postPermission = 2;
          } else {
            this.pIsV = this.$t("AllRight");
            this[command[2]].postPermission = 3;
          }
          break;
        //                    财务管理
        case "f":
          if (command[0] === "v") {
            this.fIsV = this.$t("Visiable");
            this[command[2]].financePermission = 1;
          } else if (command[0] === "h") {
            this.fIsV = this.$t("Hide");
            this[command[2]].financePermission = 0;
          } else if (command[0] === "eo") {
            this.fIsV = this.$t("EditOnly");
            this[command[2]].financePermission = 2;
          } else {
            this.fIsV = this.$t("AllRight");
            this[command[2]].financePermission = 3;
          }
          break;
        //                    行业报告
        case "i":
          if (command[0] === "v") {
            this.iIsV = this.$t("Visiable");
            this[command[2]].industryReportPermission = 1;
          } else if (command[0] === "h") {
            this.iIsV = this.$t("Hide");
            this[command[2]].industryReportPermission = 0;
          } else {
            this.iIsV = this.$t("AllRight");
            this[command[2]].industryReportPermission = 3;
          }
          break;
      }
      //                console.log('添加用户更改后',command[2],'参数',this[command[2]])
    },

    //            弹窗:初始化addAdmin
    addAdmin() {
      if (this.myRight < 2) {
        this.$message.error(this.$t("NoRight"));
      } else {
        this.addAdminDialog = true;
        this.sIsV = this.$t("Visiable");
        this.addAdminForm.systemPermission = 1;
        this.mIsV = this.$t("Visiable");
        this.addAdminForm.messagePermission = 1;
        this.uIsV = this.$t("Visiable");
        this.addAdminForm.userPermission = 1;
        this.aIsV = this.$t("Visiable");
        this.addAdminForm.administratorPermission = 1;
        this.cIsV = this.$t("Visiable");
        this.addAdminForm.contentPermission = 1;
        this.pIsV = this.$t("Visiable");
        this.addAdminForm.postPermission = 1;
        this.fIsV = this.$t("Visiable");
        this.addAdminForm.financePermission = 1;
        this.iIsV = this.$t("Visiable");
        this.addAdminForm.industryReportPermission = 1;
      }
    },

    //            添加管理员
    addAdminData(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          api.addAdmin(this.addAdminForm).then(
            (res) => {
              this.loading = false;
              // console.log("请求成功了", res.msg);
              if (res.msg === "Email is already exist") {
                this.$message.error(this.$t("EmailExist"));
              } else if (res.msg === "Email cannot be null") {
                this.$message.error(this.$t("EmailExist"));
              } else if (res.msg === "900103") {
                this.$message.error(this.$t("EmailAccountExist"));
                return false;
              } else {
                this.$message({
                  message: this.$t("AddSuccess"),
                  type: "success",
                });
                this.cancle();
                this.initUserData(1);
              }
            },
            (error) => {
              // console.log("请求失败了", error.message);
              this.$message.error(this.$t("SysBusy"));
            }
          );
        } else {
          console.log("error submit");
          return false;
        }
      });
    },

    //            弹窗：取消
    cancle() {
      this.addAdminDialog = false;
      this.addAdminForm = {
        account: "",
        avatarURL: "",
        name: "",
        passWord: "",
        mobile: "",
        adminId: this.adminId,
        systemPermission: 1,
        messagePermission: 1,
        userPermission: 1,
        administratorPermission: 1,
        financePermission: 1,
        contentPermission: 1,
        chamberId: this.chamberId,
        currentAdmin: this.adminId,
      };
    },

    //            同步选中管理员权限
    editAuthorityDialog(scope, dialog, form) {
      // console.log("authority:", scope);
      this[form].account = scope.account;
      this[form].name = scope.name;
      this[form].mobile = scope.mobile;
      this[form].email = scope.email;
      this[form].id = scope.id;
      this.sIsV = this.numToAuthority(scope.systemPermission);
      this.authorityParam.systemPermission = scope.systemPermission;
      this.mIsV = this.numToAuthority(scope.messagePermission);
      this.authorityParam.messagePermission = scope.messagePermission;
      this.uIsV = this.numToAuthority(scope.userPermission);
      this.authorityParam.userPermission = scope.userPermission;
      this.aIsV = this.numToAuthority(scope.administratorPermission);
      this.authorityParam.administratorPermission =
        scope.administratorPermission;
      this.cIsV = this.numToAuthority(scope.contentPermission);
      this.authorityParam.contentPermission = scope.contentPermission;
      this.pIsV = this.numToAuthority(scope.postPermission);
      this.authorityParam.postPermission = scope.postPermission;
      this.fIsV = this.numToAuthority(scope.financePermission);
      this.authorityParam.financePermission = scope.financePermission;
      this.iIsV = this.numToAuthority(scope.industryReportPermission);
      this.authorityParam.industryReportPermission =
        scope.industryReportPermission;
      this[dialog] = true;
    },

    //            点击更改 前端权限 => 后端num
    onChangeAuthority(type, tab, right, part) {
      switch (type) {
        case "v":
          this[tab] = this.$t("Visiable");
          this[right][part] = 1;
          break;
        case "h":
          this[tab] = this.$t("Hide");
          this[right][part] = 0;
          break;
        case "eo":
          this[tab] = this.$t("EditOnly");
          this[right][part] = 2;
          break;
        case "all":
          this[tab] = this.$t("AllRight");
          this[right][part] = 3;
          break;
      }
    },

    //            权限管理,更改权限
    handleAuthorityCommand(command) {
      console.log("command:", command);
      switch (command[1]) {
        case "s":
          this.onChangeAuthority(
            command[0],
            "sIsV",
            "authorityParam",
            "systemPermission"
          );
          break;
        case "m":
          this.onChangeAuthority(
            command[0],
            "mIsV",
            "authorityParam",
            "messagePermission"
          );
          break;
        case "u":
          this.onChangeAuthority(
            command[0],
            "uIsV",
            "authorityParam",
            "userPermission"
          );
          break;
        case "a":
          this.onChangeAuthority(
            command[0],
            "aIsV",
            "authorityParam",
            "administratorPermission"
          );
          break;
        case "c":
          this.onChangeAuthority(
            command[0],
            "cIsV",
            "authorityParam",
            "contentPermission"
          );
          break;
      }
      // console.log("authorityParam", this.authorityParam);
    },

    //            权限管理,更改权限
    editAdminData() {
      if (confirm("")) {
        // console.log("提交权限更改", this.authorityParam);
        api.editAdmin(this.authorityParam).then(
          (res) => {
            // console.log("请求成功了", res.msg);
            this.authorityDialog = false;
            this.initUserData(1);
          },
          (error) => {
            // console.log("请求失败了", error.message);
            this.$message.error(this.$t("SysBusy"));
          }
        );
      }
    },

    //            编辑管理员弹窗
    editUserDialog(tab, formName) {
      console.log("tab", tab);
      this.editAdminForm.account = tab.account;
      this.editAdminForm.personName = tab.contactName;
      this.editAdminForm.email = tab.email;
      this.editAdminForm.contactNum = tab.contactNumber;
      this.editAdminForm.userId = tab.userId;
      this.editAdminDialog = true;
      //                console.log('api连接',this.editAdminForm);
    },

    //            编辑管理员
    editUserData(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;

          // 查看改动账号是否为本账号
          if (this.editAdminForm.id === this.adminId) {
            this.$confirm(this.$t("ModifyMe"), this.$t("Attention"), {
              confirmButtonText: this.$t("ReLogin"),
              cancelButtonText: this.$t("Cancle"),
              dangerouslyUseHTMLString: true,
              type: "warning",
            })
              .then(() => {
                // 确认了的话就调接口然后退出登录
                api.editAdmin(this.editAdminForm).then((res) => {
                  this.loading = false;
                  // console.log("res.msg", res.msg);
                  this.editAdminDialog = false;
                  this.initUserData(1);
                });
                window.sessionStorage.setItem("change", 2);
                this.$store.commit("menu/clear");
                this.$router.push({ path: "/" });
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: this.$t("Canceled"),
                });
                this.loading = false;
                this.editAdminDialog = false;
              });
          } else {
            // 如果是其他管理员账号
            api.editAdmin(this.editAdminForm).then((res) => {
              this.loading = false;
              // console.log("res.msg", res.msg);
              this.editAdminDialog = false;
              this.initUserData(1);
            });
          }
        } else {
          // console.log("error submit");
          this.$message.error(this.$t("SysBusy"));
          return false;
        }
      });
    },

    // 启用管理员
    initiateAdmin(row) {
      this.$confirm(this.$t("ComfirmInit"), this.$t("Attention"), {
        confirmButtonText: this.$t("Confirm"),
        cancelButtonText: this.$t("Cancle"),
        type: "warning",
      })
        .then(() => {
          api
            .editAdmin({
              id: row.id,
              status: 0,
              adminId: this.adminId,
              currentAdmin: this.currentAdmin,
            })
            .then(() => {
              this.initUserData(1);
            });
        })
        .catch(() => {});
    },

    // 禁用管理员
    banAdmin(row) {
      this.$confirm(this.$t("ComfirmBan"), this.$t("Attention"), {
        confirmButtonText: this.$t("Confirm"),
        cancelButtonText: this.$t("Cancle"),
        type: "warning",
      })
        .then(() => {
          api
            .editAdmin({
              id: row.id,
              status: 1,
              adminId: this.adminId,
              currentAdmin: this.currentAdmin,
            })
            .then(() => {
              this.initUserData(1);
            });
        })
        .catch(() => {});
    },

    // 删除管理员
    deleteUserData(tab) {
      this.$confirm(this.$t("ComfirmDeleteRow"), this.$t("Attention"), {
        confirmButtonText: this.$t("Confirm"),
        cancelButtonText: this.$t("Cancle"),
        type: "warning",
      })
        .then(() => {
          api
            .deleteAdmin({
              id: tab.id,
              status: 2,
              adminId: this.adminId,
              currentAdmin: this.currentAdmin,
            })
            .then(() => {
              this.initUserData(1);
            });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss">
.mainBody {
  width: 1600px;
  margin-top: 5px;
}

/*.filterBarAdmin {
  !*width: 1660px;*!
  width: 99%;
  height: 50px;
  margin: 5px auto 10px auto;
  display: grid;
  grid-template-columns: 23% 23% 23% 15% 15%;
  background-color: #677D95;
  border-radius:5px;
  color: white;
  padding-top: 5px;
  input{
    width: 50%;
    border:1px solid white;
    margin:8px 0;
    float: left;
  }
  div label{
    margin:8px 0 8px 10px;
    display: block;
    float: left
  }
  .el-button {
    margin: 4px 10px 0 40px;
    width: 200px;
    height: 30px;
    line-height: 0.4;
    background-color: #677D95;
    color: white;
    &:hover{
      background-color: #9FBEE6 !important;
    }
  }
}*/

.filterBarAdmin {
  width: 99%;
  height: 130px;
  margin: 5px auto 10px auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  justify-items: center;
  background-color: #677d95;
  border-radius: 5px;
  color: white;
  padding-top: 10px;
  /*line-height: 65px;*/
  input {
    min-width: 200px;
    min-height: 30px;
    border: 1px solid white;
    vertical-align: middle;
  }

  div label {
    margin: 8px 0 8px 10px;
    display: block;
    float: left;
    width: fit-content;
  }

  .el-button {
    /*grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 4;
    grid-column-end: 5;*/
    /*margin: 4px 10px 0 120px;*/
    margin: auto;
    width: 200px;
    height: 50px;
    line-height: 0.4;
    /*line-height: 50px;*/
    background-color: #677d95;
    color: white;

    &:hover {
      background-color: #9fbee6 !important;
    }
  }
}

.navigatorAdmin {
  /*width: 1660px;*/
  width: 98.9%;
  height: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /*border:1px solid black;*/
  margin: 10px auto;

  & > span {
    padding-top: 3px;
    text-align: center;
    border: 1px solid black;
  }
}

.addAdmin {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(7, 1fr);
  grid-column-gap: 30px;
  padding: 0 20px;

  .el-dialog__body {
    word-wrap: break-word !important;
    word-break: normal !important;
  }

  .el-input__inner {
    //float: left;
    min-width: 200px;
    font-size: 20px;
  }

  .el-dropdown {
    margin-left: 40px;
  }
}

/*.editAuthorityDialog {
  width: 60%;
  height: auto;
  margin: auto;

  .editAuthorityForm {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);

    .el-dropdown {
      margin-left: 40px;
    }
  }
}*/

.el-table th {
  /*background-color: #677D95;*/
  /*background-color: #A6C3E3;*/
  background-color: #cccccc;
  color: black;
  opacity: 1;
}

.active {
  /*background-color: #1C252C;*/
  background-color: #677d95;
  color: white;
}

.table {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>

